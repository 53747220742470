<template>
	<section>
		<b-row style="justify-content: space-between" class="mb-2 align-center">
		    <b-col lg="12">
				<Breadcrumb :labels="labels" :subLabel_click="subLabel_name" />				
		    </b-col>
		</b-row>
		<b-row>
		    <b-col md="12">
		    	<div>
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" class="overview-tab">	
			            	<validation-observer ref="form_rel" #default="{invalid}" >
				                <b-form @submit.prevent class="profile-frm mb-20" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col lg="6" style="padding-left: 33px ">
							                <h2 class="mb-2"><strong>Basic Info</strong></h2>
							            </b-col>
							            <b-col lg-6>
							            	<div class="text-right mb-2">
							                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAdd" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>
							                    <template v-if="userData && (permission ? permission.edit : false)">
								                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAdd">
								                        Save
								                    </b-button>	
												</template>			                    
								                <template v-else> 
								                	<b-button type="button" variant="secondary" class="btn-df size-18 black btn-disabled-gray" disabled>
										                Save
										            </b-button> 
								                </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">              
				                      	<b-row style="width: 100%; margin: 0;">   
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Activity Name" label-cols-lg="4" class="star-required input-group-label"> 
				                              		<validation-provider
							                            #default="{ errors }"
							                            name="Activity Name"
							                            rules="required"                      
							                        >       
					                                    <b-form-input
					                                    	v-model="fieldsAdd.name"
					                                      	placeholder="Activity Name"
															:formatter="length100"
					                                    ></b-form-input>
					                                	<small class="text-danger">{{ errors[0] }}</small>
                          							</validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Product Code" label-cols-lg="4" class="star-required input-group-label">    
				                              		<validation-provider
							                            #default="{ errors }"
							                            name="Product Code"
							                            rules="required"                      
							                        >                        
					                                    <b-form-input
					                                    	v-model="fieldsAdd.product_code"
					                                      	placeholder="Product Code"
															:formatter="length50"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Project" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Project"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="getProjectName"
						                                    label="title"
						                                    :options="selectProject"
						                                    placeholder="Project"
						                                    disabled
						                                    :id="disableField"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>         
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Service Type" label-cols-lg="4" class="star-required">	
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Service Type"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedService"
						                                    label="name"
						                                    :options="selectService"
						                                    placeholder="Service"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	      
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Activity Owner" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Activity Owner"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedOwner"
						                                    label="full_name"
						                                    :options="selectActivityOwner"
						                                    placeholder="Select Owner"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>    
				                          	<b-col lg="6" class="px-0">             
				                              	<b-form-group label="Reference No." label-cols-lg="4" class="input-group-label">   
				                              		<validation-provider
							                            #default="{ errors }"
							                            name="Reference No"                    
							                        >                          
					                                    <b-form-input
					                                    	v-model="fieldsAdd.reference"
					                                      	placeholder="Ref No."
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
				                              	</b-form-group>
				                          	</b-col>     
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Activity Status" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Activity Status"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="fieldsAdd.status"
						                                    label="title"
						                                    :options="selectActivityStatus"
						                                    placeholder="Select Status"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Mode of Delivery" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Mode of Delivery"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="fieldsAdd.mode"
						                                    label="title"
						                                    :options="selectMode"
						                                    placeholder="Select a mode"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>                
					                            </b-form-group>
				                          	</b-col>                         	
				                          	<b-col lg="12" class="px-0">
												<b-form-group label="Venue" label-cols-lg="2" class="input-group-label">
													<b-form-input
														v-model="fieldsAdd.trained_indirect"
														placeholder="Venue"
														:formatter="length50"
													></b-form-input>
												</b-form-group>
				                          		<!-- <template v-if="selectedType && selectedType.value == 0">
				                          			<b-form-group label="Venue" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="fieldsAdd.trained_indirect"
						                                    placeholder="Venue"
						                                ></b-form-input>
						                            </b-form-group>
				                          		</template>
					                            <template v-else-if="selectedType && selectedType.value == 1">
					                            	<b-form-group label="Trained (Indirect)" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="fieldsAdd.trained_indirect"
						                                    placeholder="Venue"
						                                ></b-form-input>
						                            </b-form-group>
					                            </template>		
					                            <template v-else>
				                          			<b-form-group label="Trained (Indirect)" label-cols-lg="2">
						                            	<b-form-input
						                            		v-model="fieldsAdd.trained_indirect"
						                                    placeholder="Venue"
						                                ></b-form-input>
						                            </b-form-group>
				                          		</template>		                             -->
					                            <div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col>  
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Start Date" label-cols-lg="4" class="star-required">
					                            	<div class="calendarIcon">
						                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
						                        	</div>
					                                <flat-pickr
					                                	v-model="fieldsAdd.start_date"
					                                	placeholder="Select a date"
				                                      	class="form-control fieldCalendar"           
				                                      	:config="{ altInput: true , altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: detailProject.commencement_date, maxDate: detailProject.completion_date }"
				                                      	required
				                                    />
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="End Date" label-cols-lg="4" class="star-required">
					                            	<div class="calendarIcon">
						                        		<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
						                        	</div>
					                                <flat-pickr
					                                	v-model="fieldsAdd.end_date"
				                                      	class="form-control fieldCalendar"           
				                                      	:config="{ altInput: true,altFormat: 'd/m/y', dateFormat: 'Y-m-d', minDate: detailProject.commencement_date, maxDate: detailProject.completion_date}"
				                                      	placeholder="Select a date"
				                                      	required
				                                    />
					                            </b-form-group>
				                          	</b-col> 	       
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Start Date Remarks" label-cols-lg="4">
					                            	<b-form-textarea
					                            		v-model="fieldsAdd.start_remark"
					                                    placeholder="Brief Description"
					                                    rows="4"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="End Date Remarks" label-cols-lg="4">
					                            	<b-form-textarea
					                            		v-model="fieldsAdd.end_remark"
					                                    placeholder="Brief Description"
					                                    rows="4"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col> 	
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col>  			                          	
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Activity Type" label-cols-lg="4" class="star-required">
					                            	<validation-provider
							                            #default="{ errors }"
							                            name="Activity Type"
							                            rules="required"                      
							                        > 
						                                <v-select
						                                	v-model="selectedType"
						                                    label="title"
						                                    :options="activityType"
						                                    placeholder="Select type"
					                                    />
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>    
				                          	<b-col lg="6" class="px-0"></b-col>
				                          	<template v-if="selectedType">
				                          		<template v-if="selectedType.value == 1">
				                          			<b-col lg="6" class="px-0">             
							                            <b-form-group label="Leaders Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">
							                            	<b-form-input
							                            		placeholder="0"
							                                    disabled
							                                ></b-form-input>
							                            </b-form-group>
						                          	</b-col> 	     
						                          	<b-col lg="6" class="px-0">             
							                            <b-form-group label="Leaders Trained (Indirect)" label-cols-lg="4" class="star-required input-group-label">
							                            	<validation-provider
									                            #default="{ errors }"
									                            name="Leaders Trained (Indirect)"
									                            rules="required"                      
									                        >
								                            	<b-form-input
								                            		v-model="fieldsAdd.leader_indirect"
								                            		placeholder="0"
								                                ></b-form-input>
								                                <small class="text-danger">{{ errors[0] }}</small>
								                            </validation-provider>
							                            </b-form-group>
						                          	</b-col> 
				                          		</template>
					                          	<template v-else>
					                          		<b-col lg="6" class="px-0">             
							                            <b-form-group label="Specialists Trained (Direct)" label-cols-lg="4" class="label-break input-group-label">
							                            	<b-form-input
							                            		placeholder="0"
							                                    disabled
							                                ></b-form-input>
							                            </b-form-group>
						                          	</b-col> 	     
						                          	<b-col lg="6" class="px-0">             
							                            <b-form-group label="Specialists Trained (Indirect)" label-cols-lg="4" class="star-required input-group-label">
							                            	<validation-provider
									                            #default="{ errors }"
									                            name="Specialists Trained (Indirect)"
									                            rules="required"                      
									                        > 
								                            	<b-form-input
								                            		v-model="fieldsAdd.spe_indirect"
								                                    placeholder="0"
								                                ></b-form-input>
								                                <small class="text-danger">{{ errors[0] }}</small>
							                            	</validation-provider>
							                            </b-form-group>
						                          	</b-col> 
					                          	</template>
					                        </template>
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Activity Description" label-cols-lg="2">
					                            	<b-form-textarea
					                            		v-model="fieldsAdd.description"
					                                    placeholder="Description"
					                                    rows="4"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col>  
				                          	<b-col lg="12" class="px-0">
				                          		<div style="border-top: 1px solid #B1B1B1; height: 14px; "></div>
				                          	</b-col> 
				                          	<b-col lg="12" class="px-0">
					                            <b-form-group label="Activity Remarks" label-cols-lg="2">
					                            	<b-form-textarea
					                            		v-model="fieldsAdd.remarks"
					                                    placeholder="Remarks"
					                                    rows="4"
														:formatter="length1000"
					                                ></b-form-textarea>
					                            </b-form-group>
				                          	</b-col>  
				                      	</b-row>
				                      	<div class="d-lg-flex">
				                          	<div>				                              
				                          	</div>
				                      	</div>  
					                </div>
					            </b-form> 
					        </validation-observer>
			            </b-tab>
			            <b-tab title="Gifts" disabled>
			            </b-tab>
			            <b-tab title="Documents" class="tab-child tab-pt-0" disabled>
			            </b-tab>    
			            <b-tab title="Users" class="tab-child tab-pt-0" disabled>
			            </b-tab>                        
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>

<script>

	const moment = require('moment')

	import Breadcrumb from '@/views/itees/admin-dashboard/partial/Breadcrumb';
	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
	import globalAdmin from '../../../model/globalAdmin'
	import {
		mapActions,
		mapState
	} from 'vuex'
	
	export default {
		mixins: [globalAdmin],
	  	components: {
	  		Breadcrumb,
	  		flatPickr,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
	  	},
	  	data() {
		    return {
		    	disableField: 'disabled-field',
		    	searchQuery: null,
				permission: [],
				userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "our-portfolio-project-detail": "" },
			    subLabel_name: {"our-portfolio-project-detail-edit-addactivity": ''},
			    fieldsAdd: {
			    	name: null,
			    	product_code: null,
			    	reference: null,
			    	status: null,
			    	mode: null,
			    	trained_indirect: null,
			    	start_date: null,
			    	end_date: null,
			    	start_remark: null,
			    	end_remark: null,
			    	description: null,
			    	remarks: null,
			    	leader_indirect: null,
			    	spe_indirect: null
			    },
			    activityType: [
			    	{title: 'Specialists', value: 0},
                	{title: 'Leaders', value: 1},                	
                ],
                selectedType: null,

                selectProject: [],   
                getProjectName: null,

				detailProject: [],

                selectService: [],
		        selectedService: null,

                selectActivityOwner: [],
                selectedOwner: null,

                selectActivityStatus: [
                  { title: 'Not Done', value: 0 },
                  { title: 'In Progress ', value: 1 },
                  { title: 'Done', value: 2 },
                  { title: 'Terminated', value: 3 },
                ],
                selectMode: [
                  { title: 'Online', value: 0},
                  { title: 'Face to Face', value: 1 },
                ],
                permission_edit: null
            }
		},	
		watch: {
			permissions:function(val){
				this.permission = val
				if(!val.edit){
					this.$router.push({ path: `/admin/dashboard` })
				}
			}
		},	
		computed: mapState({
			permissions: state => state.auth.permissionOurPortfolio,
		}),
		created(){
			this.permission = this.checkPermissionOurPortfolio(this.userData,"Project",this.$route.params.project_id,"Project") ?? []
			this.fetchCountryID()
			this.getNameProject()
			this.listOwner()
			this.listServicesSetup()
			this.getPermission()
			this.mintoday()
		},
		
		methods: {
			...mapActions({
				checkPermisionOurPortfolio: 'auth/checkPermisionOurPortfolio',   
			}),
			mintoday(){            
	            var d = new Date()
	            d.setDate(d.getDate())
	            var now = moment(d).format('YYYY-MM-DD')
	            return now
	        },
			getPermission(){
          		this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id	
				})
				.then(response => {
					this.permission_edit = response.data.data.permission_edit
				})
				.catch((error) => {
					console.log(error)
				})
          	},
			length50(e){
	          	return String(e).substring(0,50)
	      	},
			length20(e){
	          	return String(e).substring(0,20)
	      	},
	      	length100(e){
	          	return String(e).substring(0,100)
	      	}, 
			length1000(e){
	          	return String(e).substring(0,1000)
	      	}, 
			fetchCountryID(){
				this.$store
				.dispatch('country/fetchCountryID', this.$route.params.id)
				.then(response => {
					this.labels['our-portfolio-project-detail'] = response.data.data.name
					// response.data.data.name = 'viet nam'
				})
				.catch((error) => {
					console.log(error)
				})
			},
			deteleItem(item){
			},
			getNameProject(){
				this.$store
				.dispatch('project/detailProjectList', {
					id: this.$route.params.project_id	
				})
				.then(response => {
					this.subLabel_name['our-portfolio-project-detail-edit-addactivity'] = response.data.data.name
					this.getProjectName = response.data.data.name
					this.detailProject = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			listOwner(){
				this.$store
				.dispatch('auth/fetch_staff_log_list', {
		            filter: {
		            	"group":"1",
						"active":"1"
		            }
		        })
				.then(response => {
					this.selectActivityOwner = response.data.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},			
			listServicesSetup(){
				this.$store
				.dispatch('auth/fetchServicesSetup', {
					filter: {
					  "list_search": [
					    {
					      "field": "status",
					      "keyword": 1
					    }
					  ]
					}
				})
				.then(response => {
					this.selectService = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
			cancelAdd(){
				this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}/edit/${this.$route.params.project_id}`, query: {tab: 1} })
			},
			saveAdd(){
				this.$refs.form_rel.validate().then(success => {
            		if (success){

            			let data_Activity = {}

            			if( this.selectedType &&  this.selectedType.value == 1 ){ // leader
            				data_Activity = {
			                    name: this.fieldsAdd.name,
			                    product_code: this.fieldsAdd.product_code,
			                    project_id: this.$route.params.project_id,
			                    service_type: this.selectedService ? this.selectedService.id : null,
			                    owner: this.selectedOwner ? this.selectedOwner.id : null, 
			                    reference_no: this.fieldsAdd.reference,
			                    status: this.fieldsAdd.status ? this.fieldsAdd.status.value : null,
			                    mode_of_delivery: this.fieldsAdd.mode ? this.fieldsAdd.mode.value : this.fieldsAdd.mode,
			                    commencement_date: this.fieldsAdd.start_date,
			                    commencement_remark: this.fieldsAdd.start_remark,
			                    completion_date: this.fieldsAdd.end_date,
			                    completion_remark: this.fieldsAdd.end_remark,
			                    activity_type: this.selectedType ? this.selectedType.value : null,
			                    description: this.fieldsAdd.description,
			                    remarks: this.fieldsAdd.remarks,
			                    number_people_indirect: this.fieldsAdd.leader_indirect,
			                    venue: this.fieldsAdd.trained_indirect,
			                }
            			}
            			else{
            				data_Activity = {
			                    name: this.fieldsAdd.name,
			                    product_code: this.fieldsAdd.product_code,
			                    project_id: this.$route.params.project_id,
			                    service_type: this.selectedService ? this.selectedService.id : null,
			                    owner: this.selectedOwner ? this.selectedOwner.id : null, 
			                    reference_no: this.fieldsAdd.reference,
			                    status: this.fieldsAdd.status ? this.fieldsAdd.status.value : null,
			                    mode_of_delivery: this.fieldsAdd.mode ? this.fieldsAdd.mode.value : this.fieldsAdd.mode,
			                    commencement_date: this.fieldsAdd.start_date,
			                    commencement_remark: this.fieldsAdd.start_remark,
			                    completion_date: this.fieldsAdd.end_date,
			                    completion_remark: this.fieldsAdd.end_remark,
			                    activity_type: this.selectedType ? this.selectedType.value : null,
			                    description: this.fieldsAdd.description,
			                    remarks: this.fieldsAdd.remarks,
			                    number_people_indirect: this.fieldsAdd.spe_indirect,
			                    venue: this.fieldsAdd.trained_indirect,
			                }
            			}            			
		                // console.log('data_Activity: ', data_Activity)

		                this.$store
		                .dispatch('project/addActivity', data_Activity)
		                .then(response => {
		                    this.$toast({
		                        component: ToastificationContent,
		                        props: {
		                            title: this.fieldsAdd.name + ' Created!',
		                            variant: 'success',
		                        },
		                    },
		                    {
		                        position: 'top-center'
		                    })

		                	this.$router.push({ path: `/admin/our-portfolio/project/${this.$route.params.id}/edit/${this.$route.params.project_id}`, query: {tab: 1} })
		                	
		                })
		            	.catch((error) => {
		                  	console.log(error)
		                  	this.$toast({
		                      	component: ToastificationContent,
		                      	props: {
		                        	title: error.response.data.message,
		                        	variant: 'danger',
		                      	},
		                    },
		                    {
		                      position: 'top-center'
		                    })
		                })  

            		}
					else {
						this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
					}
            	})
			}
		}
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";

	#modalPopover .modal-dialog, #modalUsers .modal-dialog{
		max-width: 650px;
	}
	.gift-table table thead tr th:first-child{
		min-width: 61px
	}
	.gift-table table thead tr th:last-child{
		min-width: 110px
	}
	#modalPopover .modal-dialog .modal-header, #modalUsers .modal-dialog .modal-header{
		border-bottom: 1px solid rgba(0, 0, 0, 0.7);
	}
	#modalPopover .modal-dialog .row-container, #modalUsers .modal-dialog .row-container{
		padding-right: 80px;
    	padding-left: 50px;
	}
	#modalPopover .modal-dialog .modal-body, #modalUsers .modal-dialog .modal-body{
		padding-top: 30px;
    	padding-bottom: 0;
	}
	#modalPopover .modal-dialog .modal-footer, #modalUsers .modal-dialog .modal-footer{
		padding-bottom: 30px; 
	}
	.tab-pt-0 .px-table-0 > .card-body {
	    padding-top: 0;
	}
</style>